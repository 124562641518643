import React, { Component } from "react";
import styles from "./instructions.module.css";

class Instructions extends Component {
  state = {};
  render() {
    return (
      <article className={styles.instructions}>
        <p>
          A Hanjie puzzle is a blank grid, of varying size, for instance nine
          rows by nine columns.
        </p>
        <p>
          At the right hand side and bottom of each puzzle are a set of numbers.
          These numbers dictate how many cells in that row or column must be
          coloured in, working from left to right or top to bottom, as
          appropriate.
        </p>
        <p>
          Thus, if at the bottom of a column there is the number 4, it means 4
          cells in that column - no more, no less - must be coloured in. If at
          the right hand edge of a row you see 1, 2, 3 that means that there are
          three discrete sets of cells that must be coloured in. A comma means
          there is at least one blank square between each set of coloured
          squares, though there could be more than one square. Thus with 1, 2, 3
          there must be at least 8 cells in the row.
        </p>
        <p>
          All you need to play the puzzle is the grid and the clues. Then using
          logic alone, you can deduce which cells in each appropriate row and
          column must be coloured in. This may be immediately obvious, or may
          need to be deduced by a combination of cross-referencing the different
          columns and rows where they intersect, and through elimination.
        </p>
      </article>
    );
  }
}

export default Instructions;
