import React, { Component } from "react";
import "./App.css";
import Hanjie from "../../components/Hanjie/Hanjie";
import Instructions from "../../components/Instructions";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">Hanjie</header>
        <Instructions />
        <Hanjie />
      </div>
    );
  }
}

export default App;
